<main *ngIf="mServ.pages.resultats">
  <section [style.background-color]="mServ.menu.couleurGauche.code"
    [style.background-image]="'url(' + (mServ.menu.medias[0].url | mediaPipe) + ')'" class="img">
    <article *ngIf="getPremier().Intro">
      <a href="https://vinciplateforme.web.app" target="_blank" class="cartouche">
        <figure [style.background-image]="'url(' + (getPremier().Media.url | mediaPipe) + ')'"></figure>
        <div [innerHtml]="getPremier().Intro | mark"></div>
      </a>
    </article>
  </section>
  <section [style.background-color]="mServ.menu.couleurDroite.code" class="contenu">
    <h1>{{ mServ.menu['titre_'+mServ.langue] }}</h1>
    <article *ngFor="let a of mServ.pages.resultats | lang:mServ.langue; let i=index" class="onglets">
      <h2 *ngIf="a.ordre > 0" (click)="setOnglet(i)">{{ a.Titre }}</h2>
      <div [innerHtml]="a.Contenu | mark" *ngIf="onglet == i || a.ordre == 0" [@inOutAnimation]></div>
    </article>
  </section>
</main>
