<main>
    <section [style.background-color]="mServ.menu.couleurGauche.code" class="noir">
        <h1>{{ mServ.menu['titre_'+mServ.langue] }}</h1>
        <h2>{{ mServ.getTraduction('principal') }}</h2>
        <ul>
            <li *ngFor="let p of (mServ.menus | menusPipe:'principal')">
                <a href [routerLink]="'/'+p.chemin" [title]="p['titre_'+mServ.langue]">
                    <i [class]="'fas ' + p.icone"></i>{{ p['titre_'+mServ.langue] }}
                </a>
            </li>
        </ul>
        <span class="separation"></span>
        <h2>{{ mServ.getTraduction('secondaire') }}</h2>
        <ul>
            <li *ngFor="let m of (mServ.menus | menusPipe:'pied')">
                <a href [routerLink]="'/'+m.chemin" [title]="m['titre_'+mServ.langue]">
                    <i [class]="'fas ' + m.icone"></i>{{ m['titre_'+mServ.langue] }}
                </a>
            </li>
        </ul>
    </section>
    <section [style.background-color]="mServ.menu.couleurDroite.code"></section>
</main>