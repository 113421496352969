<main>
    <section [style.background-color]="mServ.menu.couleurGauche.code" class="contenu">
        <h1>{{ mServ.menu['titre_'+mServ.langue] }}</h1>
        <article *ngFor="let a of mServ.pages[mServ.menu.chemin] | lang:mServ.langue">
            <!-- <h2 *ngIf="a.intro">{{ a.intro }}</h2> -->
            <div>
                <div [innerHtml]="a.contenu | mark"></div>
                <label class="switch" (click)="mServ.setRgpd()">
                <input type="checkbox" [checked]="mServ.rgpd" (click)="mServ.setRgpd()">
                <span class="slider"></span>
            </label>
            </div>
        </article>
    </section>
    <section [style.background-color]="mServ.menu.couleurDroite.code" [style.background-image]="'url(' + (mServ.menu.medias[0].url | mediaPipe) + ')'" class="img">
    </section>
</main>