<header class="logo">
    <div>
        <img src="assets/images/uk.png" (click)="mServ.setLangue('en')">
        <img src="assets/images/es.png" (click)="mServ.setLangue('es')">
        <img src="assets/images/fr.png" (click)="mServ.setLangue('fr')">
        <img src="assets/images/pt.png" (click)="mServ.setLangue('pt')">
    </div>
    <a href href="https://www.interreg-sudoe.eu/" title="interreg-sudoe" target="_blank" class="logosudoe">
      <img src="assets/images/logos/logo_interreg.png" title="Interreg Sudoe" alt="Logo Interreg Sudoe" />
  </a>
    <a href routerLink="/" title="VInCI">
        <img src="assets/images/Logo_VinCi_nb_web.png" title="Projet VInCI" alt="Logo du projet VInCI" />
    </a>
    <i class="fas fa-bars"></i>
    <input type="checkbox" class="mobile" #check>
    <nav>
        <ul>
            <li *ngFor="let m of (mServ.menus | menusPipe:'principal' | slice:1)">
                <a (click)="goTo(m.chemin); check.checked=''" [title]="m['titre_'+mServ.langue]">
                    <i [class]="'fas ' + m.icone"></i>{{ m['titre_'+mServ.langue] }}
                </a>
            </li>
        </ul>
    </nav>
</header>
<nav>
    <ul>
        <li *ngFor="let p of (mServ.menus | menusPipe:'pied' | lang:mServ.langue)">
            <a href [routerLink]="p.chemin" [title]="p['titre_'+mServ.langue]">
                <i [class]="'fas ' + p.icone"></i>
            </a>
        </li>
    </ul>
</nav>
