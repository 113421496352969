import { Component, OnInit } from '@angular/core';
import { MenusService } from 'src/app/utils/services/menus.service';
import { ToolsService } from 'src/app/utils/tools.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-resultats',
  templateUrl: './resultats.component.html',
  styleUrls: ['./resultats.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('.4s ease-out',
                    style({ height:500, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height:500, opacity: 1 }),
            animate('.3s ease-in',
                    style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ResultatsComponent implements OnInit {

  onglet:number = 0; // L'onglet à ouvrir

  constructor(public mServ:MenusService, public toolsServ:ToolsService) { }

  ngOnInit(): void {
    console.log(this.mServ.menu.chemin, this.mServ.pages[this.mServ.menu.chemin]);
  }
  /** Gérer les onglets */
  setOnglet(i:number){
    this.onglet == i ? this.onglet = 0 : this.onglet = i;
  }
  /** Récupérer le premier articles */
  getPremier(){
    return this.mServ.pages.resultats.find(a => a.ordre == 0 && a.langue.code == this.mServ.langue);
  }
}
